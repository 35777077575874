<template>
  <v-container
    fluid
    :class="{ bgBlack: isHovered }"
    style="position: fixed; min-height: 100vh; overflow-y: scroll "
    v-on:dragover.prevent="handleDragOver"
    v-on:dragleave.prevent="handleDragLeave"
    @drop.prevent="handleFileDrop"
  >
    <v-row style="height: 100vh" class="ml-6">
      <v-col class="ml-6 d-flex flex-column justify-start">
        <v-card elevation="1" class="mb-3" :class="{ lowOpacity: isHovered }">
          <vue-json-to-csv
            v-if="role === 'admin' || role === 'corporate'"
            :json-data="documentsCSV"
            :csv-title="'documents'"
          >
            <v-btn
              color="indigo"
              class="ma-6"
              outlined
              style="position: absolute; top: 0px; right: 0px;"
            >
              Download
              <v-icon>mdi-excel</v-icon>
            </v-btn>
          </vue-json-to-csv>
          <v-card-title>Documents</v-card-title>
          <v-card-subtitle
            >Enter a keyword, category, date range or 'uploaded by' to find
            relevant documents</v-card-subtitle
          >
          <v-card-title>
            <v-row>
              <v-col>
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Search"
                  single-line
                  hide-details
                  clearable
                ></v-text-field>
              </v-col>
              <v-col>
                <v-select
                  v-model="searchCategory"
                  :items="searchCategories"
                  label="Category"
                  clearable
                ></v-select>
              </v-col>
              <v-col>
                <v-select
                  v-model="dateRange"
                  :items="dateRanges"
                  label="Date"
                  clearable
                ></v-select>
              </v-col>
              <v-col>
                <v-select
                  v-model="author"
                  :items="compAuthors"
                  label="Uploaded By"
                  clearable
                ></v-select>
              </v-col>
            </v-row>
          </v-card-title>
          <v-data-table
            :headers="headers"
            :items="compRawData"
            :sort-by="['createdOn']"
            :sort-desc="[true]"
            :footer-props="{'items-per-page-options':[5, 10, 50, 100, 500, -1]}"
          >
            <template v-slot:item.title="{ item }">
              <div v-if="item.score" v-html="`${item.highlighted}`"></div>
              <div v-else v-html="`${item.title}`"></div>
            </template>
            <template v-slot:item.createdOn="{ item }">
              {{ friendlyDate(item.createdOn) }}
            </template>
            <template v-slot:item.id="{ item }">
              <v-btn
                v-if="role === 'admin' || role === 'corporate'"
                class="ma-2"
                icon
                color="indigo"
                @click="editDocument(item._id)"
              >
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
              <v-btn
                v-if="role === 'admin' || role === 'corporate'"
                class="ma-2"
                icon
                color="indigo"
                @click="deleteDocument(item._id)"
              >
                <v-icon>mdi-delete</v-icon>
              </v-btn>
              <v-btn
                v-if="role === 'admin' || role === 'corporate'"
                class="ma-2"
                icon
                color="indigo"
                @click="toggleFavorite(item._id)"
              >
                <ToggleFavorite :document="item" />
              </v-btn>
              <v-btn
                class="ma-2"
                icon
                color="indigo"
                @click="logActivity(item.title)"
                :href="item.URL"
                target="_blank"
              >
                <v-icon>mdi-download</v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </v-card>
        <v-card
          elevation="1"
          class="mb-3"
          :class="{ bgGreen: isTargetHovered }"
          v-on:dragover.prevent="handleTargetDragOver"
          v-on:dragleave.prevent="handleTargetDragLeave"
          v-if="role === 'admin' || role === 'corporate'"
          style="opacity: 1!important;"
        >
          <v-card-title>Document Management</v-card-title>
          <v-card-subtitle
            >Add a new document via OneDrive or shared link</v-card-subtitle
          >
          <v-card-text>
            <v-form ref="form" v-model="valid" lazy-validation>
              <v-row>
                <v-col cols="12" md="3">
                  <v-text-field
                    v-model="title"
                    :rules="[rules.required]"
                    label="Title"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="3">
                  <v-select
                    v-model="category"
                    :items="categories"
                    label="Category"
                    :rules="[rules.required]"
                    required
                  ></v-select>
                </v-col>
                <v-col cols="12" md="3">
                  <v-text-field
                    v-if="!showFilePicker"
                    v-model="url"
                    :rules="[rules.required]"
                    label="URL"
                    required
                  ></v-text-field>
                  <v-file-input
                    v-else
                    truncate-length="15"
                    label="Select File"
                    v-model="filename"
                    clearable
                    @change="handleFileSelect"
                  ></v-file-input>
                </v-col>
                <v-col cols="12" md="3">
                  <v-switch v-model="showFilePicker" inset>
                    <template v-slot:label>
                      Upload to OneDrive
                    </template>
                  </v-switch>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="3" class="text-left">
                  <v-btn
                    class=""
                    v-if="!showFilePicker"
                    outlined
                    color="indigo"
                    @click="addDocument"
                  >
                    Save Document
                  </v-btn>
                  <v-btn
                    class=""
                    v-if="showFilePicker"
                    outlined
                    color="indigo"
                    :loading="uploading"
                    @click="saveUploadedDocument"
                  >
                    Upload & Save Document
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
        </v-card>
        <v-card
          elevation="1"
          class="mb-3"
          :class="{ lowOpacity: isHovered }"
          v-if="role === 'admin' || role === 'corporate'"
        >
          <v-card-title>Invalid Documents</v-card-title>
          <v-card-subtitle
            >The following scan will show any documents which return an error
            from Datto/Google Drive:</v-card-subtitle
          >
          <v-card-text>
            <v-btn
              class="ma-2"
              outlined
              color="indigo"
              @click="getInvalidDocuments"
            >
              Scan Now
            </v-btn>
            <v-data-table
              :headers="headers"
              :items="invalidData"
              :sort-by="['createdOn']"
              :sort-desc="[true]"
              :loading="invalidScanning"
              loading-text="Scanning all documents..."
            >
              <!-- :search="search" -->
              <template v-slot:item.title="{ item }">
                <div v-if="item.score" v-html="`${item.highlighted}`"></div>
                <div v-else v-html="`${item.title}`"></div>
              </template>
              <template v-slot:item.createdOn="{ item }">
                {{ friendlyDate(item.createdOn) }}
              </template>
              <template v-slot:item.id="{ item }">
                <v-btn
                  v-if="role === 'admin' || role === 'corporate'"
                  class="ma-2"
                  icon
                  color="indigo"
                  @click="editDocument(item._id)"
                >
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
                <v-btn
                  v-if="role === 'admin' || role === 'corporate'"
                  class="ma-2"
                  icon
                  color="indigo"
                  @click="deleteDocument(item._id)"
                >
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
                <v-btn
                  v-if="role === 'admin' || role === 'corporate'"
                  class="ma-2"
                  icon
                  color="indigo"
                  @click="toggleFavorite(item._id)"
                >
                  <!-- {{ item.isFavorite }} -->
                  <ToggleFavorite :document="item" />
                </v-btn>
                <v-btn
                  class="ma-2"
                  outlined
                  color="indigo"
                  @click="logActivity(item.title)"
                  :href="item.URL"
                  target="_blank"
                >
                  Download
                </v-btn>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <!-- edit dialog -->
    <v-dialog v-model="editDocumentDialog" width="80vw">
      <v-card>
        <v-card-title class="">
          Quick Edit
          <span></span>
          <v-spacer></v-spacer>
          <v-btn icon>
            <v-icon color="indigo" @click="editDocumentDialog = false"
              >mdi-close</v-icon
            >
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-row>
              <v-col cols="12" md="3">
                <v-text-field
                  v-model="editedDocument.title"
                  :rules="[rules.required]"
                  label="Title"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="3">
                <v-select
                  v-model="editedDocument.category"
                  :items="categories"
                  label="Category"
                  :rules="[rules.required]"
                  required
                ></v-select>
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field
                  v-model="editedDocument.URL"
                  :rules="[rules.required]"
                  label="URL"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="3" class="text-right"></v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="ma-2" outlined color="indigo" @click="updateDocument">
            Update
          </v-btn>
          <!-- <v-btn
          v-if="
            (role === 'admin' || role === 'corporate') && content.isArchived
          "
          class="ma-2"
          outlined
          color="indigo"
          @click="sendRestore(content._id)"
        >
          Restore
        </v-btn> -->
          <!-- <v-btn class="ma-2" outlined color="indigo" @click="editDocumentDialog = false">
          Close
        </v-btn> -->
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import helpers from "@/plugins/helpers";
const axios = require("axios").default;
axios.defaults.withCredentials = true;
import moment from "moment";
// import rawData from "@/assets/MOCK_DATA.json";
import ToggleFavorite from "@/components/ToggleFavorite.vue";
var fuzzy = require("fuzzy");
import VueJsonToCsv from "vue-json-to-csv";
export default {
  name: "Documents",
  components: {
    ToggleFavorite,
    VueJsonToCsv
  },
  data: () => ({
    search: "",
    invalidData: [],
    invalidScanning: false,
    headers: [
      { text: "Title", value: "title" },
      { text: "Category", value: "category" },
      { text: "Date", value: "createdOn" },
      { text: "Uploaded By", value: "author" },
      { text: "", align: "end", value: "id", filterable: false }
    ],
    valid: true,
    rules: {
      required: value => !!value || "Required."
    },
    title: "",
    searchCategory: "",
    searchCategories: [
      "All Categories",
      "Catalog Content/Vendor Promotions",
      "Common Marketing Links",
      "Company Contact List",
      "Conventions/Trade Shows/Events",
      "Core Product Assortment",
      "Customer Service Claim Form",
      "FRS Quick Reference Guides",
      "OPS Manual",
      "Product Cards",
      "Product Image Guide",
      "Product Videos",
      "Marketing News",
      "New Product Expo Information",
      "Medical Insurance Information",
      "RTB Logos",
      "RTB Store Floor Plans",
      "Synchrony Tran Codes",
      "Vendor Program Information",
      "Visual Merchandising",
      "Training",
      "Warranties"
    ],
    category: "",
    categories: [
      "Catalog Content/Vendor Promotions",
      "Common Marketing Links",
      "Company Contact List",
      "Conventions/Trade Shows/Events",
      "Core Product Assortment",
      "Customer Service Claim Form",
      "FRS Quick Reference Guides",
      "OPS Manual",
      "Product Cards",
      "Product Image Guide",
      "Product Videos",
      "Marketing News",
      "New Product Expo Information",
      "Medical Insurance Information",
      "RTB Logos",
      "RTB Store Floor Plans",
      "Synchrony Tran Codes",
      "Vendor Program Information",
      "Visual Merchandising",
      "Training",
      "Warranties"
    ],
    dateRange: "",
    dateRanges: [
      "Last 7 Days",
      "Last 30 Days",
      "Last 6 Months",
      "Last 12 Months"
    ],
    url: "",
    author: "",
    editDocumentDialog: false,
    editedDocument: {
      title: "",
      category: "",
      URL: ""
    },
    showFilePicker: true,
    isHovered: false,
    isTargetHovered: false,
    uploadFile: null,
    filename: null,
    uploading: false
  }),
  computed: {
    token() {
      return this.$store.state.user.token;
    },
    role() {
      return this.$store.state.user.role;
    },
    documentsCSV() {
      let data = this.$store.state.documents;
      // prepare CSV data for download
      let csvData = [];
      data.forEach(u => {
        let row = {
          _id: u._id,
          createdOn: u.createdOn,
          title: u.title,
          URL: u.URL,
          author: u.author,
          isFavorite: u.isFavorite
        };
        csvData.push(row);
      });
      return csvData;
    },
    compRawData() {
      let data = this.$store.state.documents;
      // fuzzy filter on search
      if (this.search) {
        var list = data;
        var options = {
          pre: "<b>",
          post: "</b>",
          extract: function(element) {
            return element.title;
          }
        };
        var filtered = fuzzy.filter(this.search, list, options);
        var results = filtered.map(function(entry) {
          entry.original.score = entry.score;
          entry.original.highlighted = entry.string;
          // entry.original.title = `${entry.score} - ${entry.original.title}`;
          return entry.original;
          // return `${entry.score} - ${entry.original}`;
        });

        data = results;

        // // sort by date desc
        // data.sort((a, b) => {
        //   let da = new Date(a.createdOn),
        //     db = new Date(b.createdOn);
        //   return db - da;
        // });

        // sort by score desc, and then by date desc
        data.sort(function(a, b) {
          if (a.score === b.score) {
            // date is only important when scores are the same
            let da = new Date(a.createdOn),
              db = new Date(b.createdOn);
            return db - da;
          }
          return a.score < b.score ? 1 : -1;
        });
      }
      // filter on category
      if (this.searchCategory && this.searchCategory !== "All Categories") {
        data = data.filter(d => d.category === this.searchCategory);
      }
      // filter on dateRange
      if (this.dateRange) {
        let dateStart = moment();
        let dateEnd = moment().endOf("day");
        switch (this.dateRange) {
          case "Last 7 Days":
            dateStart = moment().subtract(7, "days");
            break;
          case "Last 30 Days":
            dateStart = moment().subtract(30, "days");
            break;
          case "Last 6 Months":
            dateStart = moment().subtract(6, "months");
            break;
          case "Last 12 Months":
            dateStart = moment().subtract(12, "months");
            break;
        }
        data = data.filter(d =>
          moment(d.createdOn).isBetween(dateStart, dateEnd)
        );
      }
      // filter on author
      if (this.author) {
        data = data.filter(d => d.author === this.author);
      }
      return data;
    },
    compAuthors() {
      let documents = this.$store.state.documents;
      const unique = [...new Set(documents.map(item => item.author))];
      return unique;
    }
  },
  methods: {
    addDocument() {
      let vm = this;
      let data = vm.$store.state.documents;
      if (this.$refs.form.validate()) {
        let document = {
          id: vm.getId(),
          title: vm.title,
          category: vm.category,
          date: moment().format("MM/DD/YYYY"),
          author: this.$store.state.user.firstname,
          URL: vm.url,
          isFavorite: false
        };

        // send document to API
        axios.defaults.headers.common = {
          Authorization: `Bearer ${vm.token}`
        };
        axios
          .post(vm.$root.urls.api + "/document", {
            document
          })
          .then(function(results) {
            data.push(document);
            // store in application state
            vm.$store.commit("saveDocuments", {
              documents: data
            });
            // log this activity
            vm.logActivity(document.title, "Added document");
            // clear form
            vm.reset();
            // toast
            vm.$toasted.show("Document added").goAway(2500);
          });

        // let activity = {
        //   user: {
        //     username: `${this.$store.state.user.firstname} ${this.$store.state.user.lastname}`,
        //   },
        //   date: moment().format("MM/DD/YYYY"),
        //   action: `Added document: ${document.title} ${document.id}`,
        // };
        // this.$store.commit("addActivity", {
        //   activity,
        // });
      }
    },
    updateDocument() {
      let vm = this;
      let data = vm.$store.state.documents;
      if (this.$refs.form.validate()) {
        let document = {
          _id: vm.editedDocument._id,
          title: vm.editedDocument.title,
          category: vm.editedDocument.category,
          URL: vm.editedDocument.URL
        };

        // send document to API
        axios.defaults.headers.common = {
          Authorization: `Bearer ${vm.token}`
        };
        axios
          .put(vm.$root.urls.api + "/document", {
            document
          })
          .then(function(results) {
            // data.forEach((d) => {
            //   if (d._id === document._id) {
            //     d.title = document.title;
            //     d.category = document.category;
            //     d.URL = document.URL;
            //   }
            // });
            // // store in application state
            // vm.$store.commit("saveDocuments", {
            //   documents: data,
            // });
            // log this activity
            vm.logActivity(document.title, "Edited document");
            // clear form
            vm.editedDocument = {
              title: "",
              category: "",
              URL: ""
            };
            // toast
            vm.$toasted.show("Document updated").goAway(2500);
            vm.editDocumentDialog = false;
          });
      }
    },
    reset() {
      this.$refs.form.reset();
    },
    getId() {
      return Math.floor(Math.random() * 10000 + 1000);
    },
    getDate() {
      var today = new Date();
      var dd = String(today.getDate()).padStart(2, "0");
      var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      var yyyy = today.getFullYear();

      today = mm + "/" + dd + "/" + yyyy;
      return today;
    },
    friendlyDate(d) {
      return helpers.formatFriendlyDate(d);
    },
    deleteDocument(id) {
      let vm = this;
      var i;
      // let data = this.compRawData;
      let data = this.$store.state.documents;
      for (i = 0; i < data.length; i++) {
        if (data[i]._id === id) {
          let document = {
            // author: data[i].author,
            // category: data[i].category,
            // date: data[i].date,
            _id: data[i]._id,
            // isFavorite: data[i].isFavorite,
            title: data[i].title
          };
          axios.defaults.headers.common = {
            Authorization: `Bearer ${vm.token}`
          };
          axios
            .delete(vm.$root.urls.api + "/document", {
              data: {
                document
              }
            })
            .then(function(results) {
              vm.$store.commit("deleteDocument", {
                document
              });
              // log the delete activity
              vm.logActivity(document.title, "Deleted");
              // toast
              vm.$toasted.show("Document deleted").goAway(2500);
            });
        }
      }
    },
    editDocument(id) {
      console.log(id);
      let data = this.$store.state.documents;
      let doc = data.filter(d => d._id === id);
      console.log(doc);
      this.editedDocument = doc[0];
      this.editDocumentDialog = true;
    },
    toggleFavorite(id) {
      // console.log(id);
      let vm = this;
      var i;
      // let data = this.compRawData;
      let data = this.$store.state.documents;
      for (i = 0; i < data.length; i++) {
        if (data[i]._id === id) {
          let document = {
            author: data[i].author,
            category: data[i].category,
            date: data[i].date,
            _id: data[i]._id,
            isFavorite: !data[i].isFavorite,
            title: data[i].title
          };
          // save document state via API
          axios.defaults.headers.common = {
            Authorization: `Bearer ${vm.token}`
          };
          axios
            .put(vm.$root.urls.api + "/document", {
              document
            })
            .then(function(results) {
              // update the document in application state
              vm.$store.commit("updateDocument", {
                document
              });
            });
        }
      }
    },
    logActivity(title, action = "Downloaded") {
      let vm = this;
      // log this activity
      let activity = {
        user: {
          username: `${this.$store.state.user.firstname} ${this.$store.state.user.lastname}`,
          email: this.$store.state.user.username
        },
        date: moment().format("MM/DD/YYYY"),
        action: `${action}: ${title} `
      };
      helpers.log(vm.token, vm.$root.urls.api, activity);
      return;
    },
    getInvalidDocuments() {
      let vm = this;
      vm.invalidScanning = true;
      axios.defaults.headers.common = {
        Authorization: `Bearer ${vm.token}`
      };
      axios
        .get(vm.$root.urls.api + "/documents/invalid", {})
        .then(function(results) {
          console.log(results.data);
          vm.invalidData = results.data;
          vm.invalidScanning = false;
        });
    },
    handleDragOver() {
      if (this.showFilePicker) {
        // console.log(`its a drag, man!`)
        this.isHovered = true;
      }
    },
    handleTargetDragOver() {
      if (this.showFilePicker) {
        // console.log(`its a drag, man!`)
        this.isTargetHovered = true;
      }
    },
    handleDragLeave() {
      // console.log(`whew, thats more like it`)
      this.isHovered = false;
    },
    handleTargetDragLeave() {
      // console.log(`whew, thats more like it`)
      this.isTargetHovered = false;
    },
    handleFileSelect(event) {
      //const file = event.target.files[0];
      this.uploadFile = event;
      //this.saveUploadedDocument(event);
    },
    handleFileDrop(event) {
      // this.dragging = false;
      const file = event.dataTransfer.files[0];
      this.uploadFile = file;
      this.saveUploadedDocument();
      //this.saveUploadedDocument(file);
    },
    saveUploadedDocument() {
      let vm = this;
      let data = vm.$store.state.documents;
      console.log(`save upload!`);
      if (this.$refs.form.validate()) {
        vm.uploading = true;
        let document = {
          id: vm.getId(),
          title: vm.title,
          category: vm.category,
          date: moment().format("MM/DD/YYYY"),
          author: this.$store.state.user.firstname,
          file: vm.uploadFile,
          isFavorite: false
        };
        console.log(document);
        // send document to API
        const formData = new FormData();
        formData.append("file", vm.uploadFile);
        formData.append("data", JSON.stringify(document));

        axios
          .post(vm.$root.urls.api + "/upload", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${vm.token}`
            }
          })
          .then(response => {
            // console.log("File uploaded successfully", response.data);
            let document = response.data;
            data.push(document);
            // store in application state
            vm.$store.commit("saveDocuments", {
              documents: data
            });
            // log this activity
            vm.logActivity(document.title, "Added document");
            // clear form
            vm.$refs.form.resetValidation();
            // vm.reset();
            vm.title = "";
            vm.category = "";
            vm.url = "";
            vm.uploadFile = null;
            vm.isHovered = false;
            vm.isTargetHovered = false;
            vm.filename = null;
            vm.showFilePicker = true;
            vm.uploading = false;
            // toast
            vm.$toasted.show("Document added").goAway(2500);
          })
          .catch(error => {
            console.error("Error uploading file", error);
            vm.uploading = false;
          });
      } else {
        this.$toasted
          .show("Please select a file and provide a title & category")
          .goAway(2500);
        vm.uploading = false;
      }
    }
  },
  mounted: function() {
    // parse any optional parameters passed in router
    if (this.$route.params.category) {
      this.searchCategory = this.$route.params.category;
      // hardcoded redirect to external URL:
      if (this.searchCategory === "RTB Connect Training Video") {
        window.location = "https://www.youtube.com/watch?v=Ip79a3_hPJE";
      }
    }

    // TEMP: save sample documents via API
    // let vm = this;
    // if(this.token){
    //   let docs = this.$store.state.documents;
    //   docs.forEach((d) => {
    //   let document = {
    //       title: d.title,
    //       category: d.category,
    //       author: 'Charles',
    //       URL: d.URL,
    //       isFavorite: false,
    //   }
    //   axios.defaults.headers.common = {
    //       Authorization: `Bearer ${vm.token}`,
    //     };
    //     axios
    //       .post(vm.$root.urls.api + "/document", {
    //         document,
    //       })
    //   // console.log(document);
    // })
    // }
  }
};
</script>

<style>
.bgBlack {
  background-color: #000;
}
.lowOpacity {
  opacity: 0.2;
}
.bgGreen {
  background-color: #d0f0c0 !important;
}
</style>
